/* global algoliasearch instantsearch */
import { instantMeiliSearch } from '@meilisearch/instant-meilisearch'

const search = instantsearch({
  indexName: 'OAH',
  searchClient: instantMeiliSearch(
    "https://siddlapp.law.stanford.edu",
    "0397d20d556de6293525f849203bbd5843995a251cc1523365bad0c2600fc223",
    {primaryKey: 'id', placeholderSearch : true} 
  ),
})

const MOBILE_WIDTH = 375;

search.addWidgets([
  instantsearch.widgets.stats({
    container: '#stats',
  }),
  instantsearch.widgets.searchBox({
    container: '#searchbox',
  }),
  instantsearch.widgets.menuSelect({
    container: "#name",
    attribute: "name",
    limit:500
  }),
  instantsearch.widgets.menuSelect({
    container: "#center",
    attribute: "center",
    limit:500
  }),
  instantsearch.widgets.menuSelect({
    container: "#claimant",
    attribute: "claimant1",
    limit:500
  }),
  instantsearch.widgets.menuSelect({
    container: "#year",
    attribute: "year",
    limit:500
  }),
  instantsearch.widgets.infiniteHits({
    container: '#hits',
    templates: {
      item: `
<section class="panel panel-count-0 panel-type-wysiwyg panel-odd  panel-contained panel-white has-panel-title-inside">
<div class="panel-container">
    <div class="content-wrap cw-pad cw-ep panel-has-no-title">
                <div class="pwysiwyg-columns pwysiwygc-count-1">
                            <div class="pwysiwygc-column context-content cc-simple">
                              <article class="context-content">
                                <h6><i>OAH No. {{#helpers.highlight}}{ "attribute": "number1" }{{/helpers.highlight}}</i></h5>
                                 <p>
                                 <div class="mu-cta-wrap">
                                 <div class="mu-wrap-inner mu-blocks-wrap mu-layout-eight-up">
                                 <article class="panel-mashup panel-type-mashup-image panel-mashup-layout-top mu-block" style="height: 60px;">
                                     <div class="mi-wrap pm-wrap-outer mi-layout-top bgd-white text-dark mi-has-no-image">
                                      Center: <b>{{#helpers.highlight}} { "attribute": "center" }{{/helpers.highlight}}</b>
                                     </div>
                                 </article>
                                 <article class="panel-mashup panel-type-mashup-image panel-mashup-layout-top mu-block" style="height: 60px;">
                                     <div class="mi-wrap pm-wrap-outer mi-layout-top bgd-white text-dark  mi-has-no-image">
                                       Date: <b>{{#helpers.highlight}}{ "attribute": "date" }{{/helpers.highlight}}</b>
                                     </div>                              
                                 </article>
                                 <article class="panel-mashup panel-type-mashup-image panel-mashup-layout-top mu-block" style="height: 60px;">
                                     <div class="mi-wrap pm-wrap-outer mi-layout-top bgd-white text-dark mi-has-no-image">
                                       Judge: <b>{{#helpers.highlight}} { "attribute": "name" }{{/helpers.highlight}}</b>
                                     </div>
                                 </article>
                                 <article class="panel-mashup panel-type-mashup-image panel-mashup-layout-top mu-block" style="height: 60px;">
                                     <div class="mi-wrap pm-wrap-outer mi-layout-top bgd-white text-dark  mi-has-no-image">
                                       Claimant: <b>{{#helpers.highlight}} { "attribute": "claimant1" }{{/helpers.highlight}}</b>
                                     </div>
                                 </article>

                                 {{#helpers.highlight}} { "attribute": "openning" }{{/helpers.highlight}}
                                 <br></br>
                                         </div>
                                       </div>
                                 </div>
                               </div>
                               <p><a href={{link}} class="btn btn-s">
                               Link to PDF</a>
                              </article>

                </div>
            </div>       
    </div>
</div>
</section>
`,
    },
  }),
]);

search.start();
